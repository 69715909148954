import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const quickSetupSlice = createApi({
  reducerPath: 'quickSetupSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['POST'],
  endpoints: (builder) => ({
    tagTypes: ['POST'],
    quickSetup: builder.mutation({
      query: (payload) => ({
        url: 'auth/quickSetup',
        method: 'POST',
        body: payload,
      }),
    }),
    tutorQuickSetup: builder.mutation({
      query: (payload) => ({
        url: 'auth/quickSetupTutor',
        method: 'POST',
        body: payload,
      }),
    }),
    professionalQuickSetup: builder.mutation({
      query: (payload) => ({
        url: 'auth/quickSetup-others',
        method: 'POST',
        body: payload,
      }),
    }),
    useScratchCodeSpecial: builder.mutation({
      query: (payload) => ({
        url: 'scratchcode/special',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useQuickSetupMutation,
  useTutorQuickSetupMutation,
  useProfessionalQuickSetupMutation,
  useUseScratchCodeSpecialMutation,
} = quickSetupSlice;
