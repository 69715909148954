import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const tutorsSlice = createApi({
  reducerPath: 'tutorsSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Tutors', 'Get_Tutors'],

  endpoints: (builder) => ({
    addSchool: builder.mutation({
      query: (payload) => ({
        url: `/tutor/add-school`,
        method: 'POST',
        body: payload,
      }),
    }),
    tutorAcceptTerms: builder.mutation({
      query: (payload) => ({
        url: `/tutor/terms-of-use`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getRecommendedTutorsList: builder.query({
      query: () => '/tutor/recommendations',
      providesTags: ['Get_Tutors'],
    }),
    sendTutorRequest: builder.mutation({
      query: (id) => ({
        url: `/tutor/request-tutor`,
        method: 'POST',
        body: { tutorId: id },
      }),
      invalidatesTags: [{ type: 'Get_Tutors' }],
    }),
    getTutors: builder.query({
      query: () => '/learner/my-tutor',
      providesTags: ['Tutors'],
    }),
    rateTutor: builder.mutation({
      query: (payload) => ({
        url: `learner/set-tutor-rating`,
        method: 'POST',
        body: payload,
      }),
    }),
    addTutorCredentials: builder.mutation({
      query: (payload) => ({
        url: '/auth/addTutorCredentials',
        method: 'POST',
        body: payload,
      }),
    }),
    addTutorReferee: builder.mutation({
      query: (payload) => ({
        url: '/auth/addTutorReferee',
        method: 'POST',
        body: payload,
      }),
    }),
    addTutorGuarantor: builder.mutation({
      query: (payload) => ({
        url: '/tutor/referee',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useAddSchoolMutation,
  useTutorAcceptTermsMutation,
  useGetRecommendedTutorsListQuery,
  useSendTutorRequestMutation,
  useGetTutorsQuery,
  useRateTutorMutation,
  useAddTutorRefereeMutation,
  useAddTutorGuarantorMutation,
  useAddTutorCredentialsMutation,
} = tutorsSlice;
