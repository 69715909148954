import Button from "../../components/Button";
import OnboardingWrapper from "../../layout/OnboardingWrapper";
import TopNav from "../../components/TopNav";
import Info from "../../svg/Info";
import ArrowRight from "../../svg/ArrowRight";
import { useNavigate } from "react-router-dom";

import "./index.scss";
import "../../styles/onboarding.scss";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useGetProfessionalQuery } from "../../store/service/professional";

const WebScreen = ({ handleSubmit }) => {
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token.value.token);
  // console.log(token);

  const { data, error } = useGetProfessionalQuery(undefined, {
    skip: !token,
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  const openProfessionalHome = async () => {
    if (error) {
      // console.error("Error fetching professional data:", error);
      return;
    }

    if (data) {
      // console.log("Fetched professional data:", data);
      navigate("/professional-home");
    }
  };

  return (
    <OnboardingWrapper>
      <div className="topNav">
        {/* <TopNav /> */}
        <p className="topNav__skip" onClick={openProfessionalHome}>
          Skip
        </p>
      </div>
      <section className="scratchCode">
        <div className="signup-student__wrapper">
          <main className="signup-student__left">
            <div className="signup-student__content">
              <h1 className="signup-student__title scratchCode__title">
                Enter Scratch
                <br />
                Code
              </h1>
              <p className="signup-student__title-sub">
                Kindly enter your 10-digit <br /> Scratch code.
              </p>
              <div className="signup-student__info">
                <Info />
                <p>
                  If you do not have a scratch code, you can skip this page.
                </p>
              </div>
            </div>
          </main>
          <main className="signup-student__right">
            <div className="">
              <section className="signup-student__form">
                <article className="signup-student__code">
                  <ArrowRight />
                  <input
                    className=""
                    onChange={(e) => setCode(e.target.value)}
                    placeholder="8747683912"
                    type="text"
                    value={code}
                  />
                </article>
                <br />
                <Button
                  borderRadius="8px"
                  onClick={() => handleSubmit(code)}
                  type="submit"
                >
                  Enter
                </Button>
              </section>
            </div>
          </main>
        </div>
      </section>

      {/* <div className="signup-student__progress">
        <PageFive />
      </div> */}
    </OnboardingWrapper>
  );
};

export default WebScreen;
