const Icon = () => {
  return (
    <svg
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1.5C13.1423 1.5 16.5 4.85775 16.5 9C16.5 13.1423 13.1423 16.5 9 16.5C4.85775 16.5 1.5 13.1423 1.5 9C1.5 4.85775 4.85775 1.5 9 1.5ZM9 3C7.4087 3 5.88258 3.63214 4.75736 4.75736C3.63214 5.88258 3 7.4087 3 9C3 10.5913 3.63214 12.1174 4.75736 13.2426C5.88258 14.3679 7.4087 15 9 15C10.5913 15 12.1174 14.3679 13.2426 13.2426C14.3679 12.1174 15 10.5913 15 9C15 7.4087 14.3679 5.88258 13.2426 4.75736C12.1174 3.63214 10.5913 3 9 3ZM8.9925 7.5C9.411 7.5 9.75 7.839 9.75 8.2575V12.1005C9.89297 12.1831 10.0047 12.3105 10.0679 12.463C10.1311 12.6155 10.1421 12.7846 10.0994 12.9441C10.0567 13.1036 9.96253 13.2445 9.83155 13.345C9.70057 13.4455 9.54009 13.5 9.375 13.5H9.0075C8.90802 13.5 8.80952 13.4804 8.71762 13.4423C8.62571 13.4043 8.54221 13.3485 8.47187 13.2781C8.40153 13.2078 8.34573 13.1243 8.30766 13.0324C8.26959 12.9405 8.25 12.842 8.25 12.7425V9C8.05109 9 7.86032 8.92098 7.71967 8.78033C7.57902 8.63968 7.5 8.44891 7.5 8.25C7.5 8.05109 7.57902 7.86032 7.71967 7.71967C7.86032 7.57902 8.05109 7.5 8.25 7.5H8.9925ZM9 5.25C9.19891 5.25 9.38968 5.32902 9.53033 5.46967C9.67098 5.61032 9.75 5.80109 9.75 6C9.75 6.19891 9.67098 6.38968 9.53033 6.53033C9.38968 6.67098 9.19891 6.75 9 6.75C8.80109 6.75 8.61032 6.67098 8.46967 6.53033C8.32902 6.38968 8.25 6.19891 8.25 6C8.25 5.80109 8.32902 5.61032 8.46967 5.46967C8.61032 5.32902 8.80109 5.25 9 5.25Z"
        fill="#E6B400"
      />
    </svg>
  );
};

export default Icon;
