export const tokenMiddleware = (storeAPI) => (next) => (action) => {
  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token) {
      // Save token to localStorage
      localStorage.setItem('token', token);

      // Remove only the token parameter from the URL
      urlParams.delete('token');
      const newUrl =
        window.location.pathname +
        (urlParams.toString() ? `?${urlParams.toString()}` : '') +
        window.location.hash;

      window.history.replaceState({}, document.title, newUrl);
    }
  }
  return next(action);
};
