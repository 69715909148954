import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const tutorSlice = createApi({
  reducerPath: 'tutorSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Tutor', 'Tutor_classes', 'Tutor_students'],
  endpoints: (builder) => ({
    getTutor: builder.query({
      query: () => `tutor`,
      providesTags: ['Tutor'],
    }),
    getTutorClasses: builder.query({
      query: () => '/tutor/classes',
      providesTags: ['Tutor_classes'],
    }),
    getStudents: builder.query({
      query: () => 'tutor/my-Students',
      providesTags: ['Tutor_students'],
    }),
    getInterestedStudents: builder.query({
      query: () => '/tutor/interested-Students',
      providesTags: ['Tutor_students'],
    }),
    getTutorSchoolResources: builder.query({
      query: () => 'school/resources',
      providesTags: ['Tutor_resources'],
    }),
    getTutorCommunity: builder.query({
      query: () => '/community/general',
      providesTags: ['Tutor_community'],
    }),
    getTutorSchoolCommunity: builder.query({
      query: () => '/community/school',
      providesTags: ['Tutor_SchCommunity'],
    }),
    likeCommunityPost: builder.mutation({
      query: (communityId) => ({
        url: `community/${communityId}`,
        method: 'PATCH',
      }),
    }),
    getCommunityContent: builder.query({
      query: (communityId) => `/community/${communityId}`,
    }),
    addComment: builder.mutation({
      query: (payload) => ({
        url: `/comments`,
        method: 'POST',
        body: payload,
      }),
    }),
    updateLearnerStatus: builder.mutation({
      query: (payload) => ({
        url: `/tutor/update-status`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Tutor_students'],
    }),
    getTutorSubjects: builder.query({
      query: () => '/tutor/subjects',
    }),
    getEduYearAndPeriod: builder.query({
      query: (eduLevelId) => `/edu/level-general/${eduLevelId}`,
    }),
    getTopics: builder.query({
      query: (subjectId) => `tutor/subject/${subjectId}`,
    }),
    getSyllabus: builder.query({
      query: () => '/syllabus',
    }),
    deleteTasks: builder.mutation({
      query: (searchParam) => ({
        url: `/tutor/get-tasks?search=${searchParam}`,
        method: 'DELETE',
      }),
    }),
    createEvent: builder.mutation({
      query: (payload) => ({
        url: `/tutor/create-event`,
        method: 'POST',
        body: payload,
      }),
    }),
    getTutorEvents: builder.query({
      query: () => '/events/users',
    }),
    removeLearner: builder.mutation({
      query: (payload) => ({
        url: '/tutor/remove-learner',
        method: 'POST',
        body: payload,
      }),
    }),
    createClass: builder.mutation({
      query: (payload) => ({
        url: '/tutor/create-class',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Tutor_classes'],
    }),
    getEduYear: builder.query({
      query: (id) => `edu/level-general/${id}`,
    }),
    addTutorSchool: builder.mutation({
      query: (payload) => ({
        url: '/tutor/add-school',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Tutor'],
    }),
    cancelClass: builder.mutation({
      query: (payload) => ({
        url: '/tutor/cancel-class',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Tutor_classes'],
    }),
    addTutorSubject: builder.mutation({
      query: (payload) => ({
        url: '/tutor/add-subject',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Tutor'],
    }),
    removeTutorSubject: builder.mutation({
      query: (payload) => ({
        url: '/tutor/remove-subject',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Tutor'],
    }),
    updateTutorEduLevel: builder.mutation({
      query: (payload) => ({
        url: '/auth/quickSetupTutor',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Tutor'],
    }),
    updateTutorEduLevel: builder.mutation({
      query: (payload) => ({
        url: '/auth/quickSetupTutor',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Tutor'],
    }),
    endTutorClass: builder.mutation({
      query: (payload) => ({
        url: '/tutor/end-class',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Tutor_classes'],
    }),
    updateTutorBio: builder.mutation({
      query: (payload) => ({
        url: 'tutor/add-bio',
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Tutor' }],
    }),
    inviteLearners: builder.mutation({
      query: (payload) => ({
        url: 'tutor/invite-learners',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Tutor_classes' }],
    }),
    verificationTime: builder.mutation({
      query: (payload) => ({
        url: '/verification-time',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Tutor' }],
    }),
    rescheduleClass: builder.mutation({
      query: (payload) => ({
        url: '/tutor/reschedule-class',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Tutor_classes' }],
    }),
    getTutorClassesDetails: builder.query({
      query: (id) => `tutor/live/${id}`,
    }),
  }),
});

export const {
  useGetTutorQuery,
  useGetTutorSubjectsQuery,
  useGetTutorClassesQuery,
  useGetStudentsQuery,
  useGetInterestedStudentsQuery,
  useGetTutorSchoolResourcesQuery,
  useGetTutorCommunityQuery,
  useGetTutorSchoolCommunityQuery,
  useLikeCommunityPostMutation,
  useGetCommunityContentQuery,
  useAddCommentMutation,
  useUpdateLearnerStatusMutation,
  useDeleteTasksMutation,
  useCreateEventMutation,
  useGetTutorEventsQuery,
  useRemoveLearnerMutation,
  useCreateClassMutation,
  useGetEduYearQuery,
  useGetEduYearAndPeriodQuery,
  useGetTopicsQuery,
  useGetSyllabusQuery,
  useAddTutorSchoolMutation,
  useAddTutorSubjectMutation,
  useRemoveTutorSubjectMutation,
  useUpdateTutorEduLevelMutation,
  useEndTutorClassMutation,
  useCancelClassMutation,
  useUpdateTutorBioMutation,
  useInviteLearnersMutation,
  useVerificationTimeMutation,
  useRescheduleClassMutation,
  useGetTutorClassesDetailsQuery,
} = tutorSlice;
