import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <rect
        width="24"
        height="24"
        fill="#00C56D"
        fillOpacity="0.06"
        rx="8"
      ></rect>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M15.707 11.293a1 1 0 010 1.414l-5.657 5.657a1 1 0 11-1.414-1.414l4.95-4.95-4.95-4.95a1 1 0 011.414-1.414l5.657 5.657z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
