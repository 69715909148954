import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import FailedState from "../../svg/FailedState";
import { useState } from "react";

const FailedScreen = ({ open, setOpen }) => {
  // const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  return (
    <section>
      {open ? (
        <div className="success-scratchCode" onClick={() => setOpen(!open)}>
          <article
            className="success-scratchCode__content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="success-scratchCode__failIcon">
              <div>
                <FailedState />
              </div>
            </div>
            <p className="success-scratchCode__text">Code Invalid!</p>
            <p className="success-scratchCode__text1">
              The Scratch code you entered is not valid, check the code and try
              again
            </p>
            <Button
              background="var(--not-red)"
              borderRadius="15px"
              className="success-scratchCode__button"
              onClick={() => setOpen(false)}
              // onClick={() => navigate('/sign-up/scratch-code')}
            >
              Try Again
            </Button>
          </article>
        </div>
      ) : null}
    </section>
  );
};

export default FailedScreen;
