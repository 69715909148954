import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  questions: [],
  skipped: [],
  questionLeft: 0,
  selectedAnswer: [],
};

export const questionHandleSlice = createSlice({
  name: 'questionHandleSlice',
  initialState,
  reducers: {
    updateQuestions: (state, item) => {
      state.questions = item.payload;
    },
    answered: (state, item) => {
      const { id, answer, uploads } = item.payload;
      const questions = current(state.questions);
      const findItem = questions.filter((quest) => quest._id === id)[0];
      const addItem = {
        ...findItem,
        answered: true,
        answer: answer,
        uploads: uploads,
      };
      const removeItem = questions.filter((quest) => quest._id !== id);
      const update = [...removeItem, addItem];
      state.questions = update;
    },
    setSkipped: (state, item) => {
      state.skipped = item.payload;
    },
    setQuestionLeft: (state, item) => {
      state.questionLeft = item.payload;
    },
    setSelectedAnswer: (state, item) => {
      state.selectedAnswer = item.payload;
    },
  },
});

export const { updateQuestions, answered, setQuestionLeft, setSelectedAnswer } =
  questionHandleSlice.actions;

export default questionHandleSlice.reducer;
