import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const educationLevelSlice = createApi({
  reducerPath: 'educationLevelSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Get'],
  endpoints: (builder) => ({
    tagTypes: ['Get'],
    getEducationLevel: builder.query({
      query: () => `edu/level-general`,
    }),
    getAllEduLevel: builder.query({
      query: () => ({
        url: `edu/level-all`,
      }),
    }),
    getAllEduYear: builder.query({
      query: () => ({
        url: `edu/year`,
      }),
    }),
    getAllEduPeriod: builder.query({
      query: () => ({
        url: `edu/period`,
      }),
    }),
    getEduById: builder.query({
      query: ({ schId, syllabusId }) => ({
        url: `edu/level-general/?schoolId=${schId}&syllabusId=${syllabusId}`,
      }),
    }),
  }),
});

export const {
  useGetEducationLevelQuery,
  useGetAllEduLevelQuery,
  useGetAllEduYearQuery,
  useGetAllEduPeriodQuery,
  useGetEduByIdQuery,
} = educationLevelSlice;
