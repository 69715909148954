import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const reportSlice = createApi({
  reducerPath: 'reportSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    reportLearner: builder.mutation({
      query: (payload) => ({
        url: '/tutor/report',
        method: 'POST',
        body: payload,
      }),
    }),
    reportTutor: builder.mutation({
      query: (payload) => ({
        url: '/learner/report',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const { useReportLearnerMutation, useReportTutorMutation } = reportSlice;
