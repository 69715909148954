import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const lifeSkillSlice = createApi({
  reducerPath: 'lifeSkillSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['GET'],
  endpoints: (builder) => ({
    getLifeSkill: builder.query({
      query: () => '/lifeskills/general',
    }),
    getOtherLifeSkill: builder.query({
      query: () => '/lifeskills/general-all',
    }),
    getSchoolLifeSkill: builder.query({
      query: (schoolId) => `/lifeskills/general?schoolId=${schoolId}`,
    }),
    getOneLifeSkill: builder.query({
      query: (id) => `/resource/life-skills/${id}`,
    }),
    getTutorChannelLifeSkill: builder.query({
      query: (id) => `/lifeskills/general-tutor/${id}`,
    }),
    getProfessionalChannelLifeSkill: builder.query({
      query: (id) => `/lifeskills/general/free/${id}`,
    }),
  }),
});

export const {
  useGetSchoolLifeSkillQuery,
  useGetOtherLifeSkillQuery,
  useGetLifeSkillQuery,
  useGetOneLifeSkillQuery,
  useGetTutorChannelLifeSkillQuery,
  useGetProfessionalChannelLifeSkillQuery,
} = lifeSkillSlice;
