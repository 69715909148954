import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const bookmarksSlice = createApi({
  reducerPath: 'bookmarksSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['GET', 'GET_ONE'],
  endpoints: (builder) => ({
    bookmark: builder.query({
      query: () => `bookmarks/`,
      providesTags: ['GET_ONE'],
    }),
    addBookmark: builder.mutation({
      query: (payload) => ({
        url: 'bookmarks',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'GET_ONE' }],
    }),
    bookmarkDelete: builder.mutation({
      query: (payload) => ({
        url: `bookmarks/${payload.id}`,
        method: 'DELETE',
        body: payload,
      }),
      invalidatesTags: [{ type: 'GET_ONE' }],
    }),
    getBookmarkByItem: builder.query({
      query: (id) => `bookmarks/item/${id}`,
      providesTags: ['GET_ONE'],
    }),
    getBookmarkBySection: builder.query({
      query: ({ itemId, sectionId }) =>
        `bookmarks/section/${itemId}/${sectionId}`,
      providesTags: ['GET_ONE'],
    }),
  }),
});

export const {
  useBookmarkQuery,
  useAddBookmarkMutation,
  useBookmarkDeleteMutation,
  useGetBookmarkByItemQuery,
  useGetBookmarkBySectionQuery,
} = bookmarksSlice;
