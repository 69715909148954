// import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../store/slice/tokenSlice";
import { useForm } from "react-hook-form";

import { successToast, errorToast } from "../../utility/toast";
import { useVerifyScratchCodeMutation } from "../../store/service/scratchCard";
import { useUseScratchCodeSpecialMutation } from "../../store/service/quickSetup";

import { closeModal, openModal } from "../../store/slice/modalSlice";

import { useNavigate } from "react-router-dom";

import "./index.scss";
import "../../styles/onboarding.scss";
import WebScreen from "./WebScreen";
import MobileScreen from "./MobileScreen";
import CongratulationScreen from "./CongratulationScreen";
import FailedScreen from "./Failed";

import { useGetProfessionalQuery } from "../../store/service/professional";

const ScratchCode = () => {
  const [scratchObj, setScratchObj] = useState();
  const [currentModal, setCurrentModal] = useState("scratch");
  const [open, setOpen] = useState(true);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [verifyScratchCard, verifyScratchCardRes] =
    useVerifyScratchCodeMutation();
  const [quickSetup, quickSetupResponse] = useUseScratchCodeSpecialMutation();

  const token = useSelector((state) => state.token.value.token);
  // console.log(token);

  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]);

  const verifyScratch = async (code) => {
    if (!code) {
      errorToast("Scratch code cannot be empty");
    }

    if (code.trim().length !== 10) {
      return errorToast("Scratch code must be 10 numbers");
    }

    const verifyCode = await verifyScratchCard({ code: code });
    if (verifyCode.error) {
      setCurrentModal("failed");
      setOpen(true);

      return errorToast(verifyCode.error.data.message);
    } else {
      successToast(verifyCode.data.message);
      setScratchObj(verifyCode.data.data);

      setCurrentModal("congratulation");

      const useCode = await quickSetup({ code: code });
      // console.log(useCode);
      if (useCode.error) {
        return errorToast(useCode.error.data.message);
      } else {
        navigate("/professional-home");
        return successToast(useCode.data.message);
      }
    }
  };

  return (
    <div className="quick">
      <div className="quick__big">
        <WebScreen
          handleSubmit={verifyScratch}
          verifyScratchCardRes={verifyScratchCardRes}
        />
      </div>
      <div className="quick__small">
        <MobileScreen
          handleSubmit={verifyScratch}
          verifyScratchCardRes={verifyScratchCardRes}
        />
      </div>
      {currentModal === "congratulation" ? (
        <div>
          <CongratulationScreen
            scratchObj={scratchObj}
            setCurrentModal={setCurrentModal}
          />
        </div>
      ) : currentModal === "failed" ? (
        <div>
          <FailedScreen
            open={open}
            setCurrentModal={setCurrentModal}
            setOpen={setOpen}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ScratchCode;
