import { useDispatch } from "react-redux";
import { closeModal } from "../../store/slice/modalSlice";
import Button from "../../components/Button";
import SuccessSvg from "../../svg/Success";
import { useNavigate } from "react-router-dom";

import "./index.scss";
import { useState } from "react";

const CongratulationScreen = ({ scratchObj }) => {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <section>
      {open ? (
        <div className="success-scratchCode" onClick={() => setOpen(!open)}>
          <article
            className="success-scratchCode__content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="">
              <div>
                <SuccessSvg />
              </div>
            </div>
            <p className="success-scratchCode__text">Congratulations!</p>
            <p className="success-scratchCode__text1">
              Your scratch code is valid
            </p>
            <div className="success-scratchCode__sponsor">
              <p className="success-scratchCode__sponsorText">Sponsored by</p>
              <p className="success-scratchCode__sponsorText1">
                {scratchObj?.scratchCodeFolderId?.name}
              </p>
            </div>
            <Button
              className="success-scratchCode__button"
              onClick={() => setOpen(!open)}
              // onClick={() => navigate("/sign-up/scratch-code")}
            >
              Continue
            </Button>
          </article>
        </div>
      ) : null}
    </section>
  );
};

export default CongratulationScreen;
